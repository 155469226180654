<template>
  <div class="unfinish-outer">
    <van-calendar
      v-model="showDate"
      @confirm="onConfirm"
      :max-date="maxDate"
      :min-date="minDate"
      color="#006C50"
    />
    <div class="mlist">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          class="van-clearfix"
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          finished-text=""
          @load="onLoad"
        >
          <template v-if="dataList && dataList.length > 0">
            <div
              class="groups-outer"
              :key="index"
              v-for="(item, index) in dataList"
            >
              <div class="item" :key="index" @click="showProcess(item)">
                <!-- <div class="status">待处理</div> -->
                <div class="line">
                  <div class="t-title">审批类型：</div>
                  <div class="t-txt">{{ item.processDefName }}</div>
                </div>
                <div class="line">
                  <div class="t-title">发起人：</div>
                  <div class="t-txt">{{ item.owner.name }}</div>
                </div>
                <div class="line">
                  <div class="t-title">摘要信息：</div>
                  <div class="t-txt">
                    <form-abstracts :value="item.formAbstracts"/>
                  </div>
                </div>
                <div class="line">
                  <div class="t-title">提交时间：</div>
                  <div class="t-txt">{{ item.createTime }}</div>
                </div>
                <div class="line">
                  <div class="t-title">当前节点：</div>
                  <div class="t-txt">{{ item.taskName }}</div>
                </div>
                <div class="line">
                  <div class="t-title">任务到达时间：</div>
                  <div class="t-txt">{{ item.taskCreateTime }}</div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="no-data">
              <img src="../../../assets/image/n5.png" class="img" />
              <span class="txt">暂无内容</span>
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>

    <el-drawer
      size="100%"
      direction="rtl"
      title="审批详情"
      :visible.sync="processVisible"
    >
      <instance-preview
        v-if="processVisible"
        :node-id="selectInstance.nodeId"
        :instance-id="selectInstance.instanceId"
        @handler-after="handlerAfter"
      ></instance-preview>
    </el-drawer>
    <van-popup
      v-model="actionVisible"
      closeable
      round
      position="center"
      :style="{ 'max-height': '90%' }"
    >
      <div class="popup-sku-con-outer delay-outer">
        <div class="pop-title-con">
          <span>同意审批</span>
        </div>
        <div class="input-outer">
          <span class="input-pop-title">审批意见：</span>
          <div class="edit-input">
            <el-input
              rows="2"
              placeholder="请输入审批意见"
              v-model="optionInputInfo"
              maxlength="255"
              show-word-limit
              type="textarea"
            ></el-input>
          </div>
        </div>
        <div class="option-btns">
          <van-button type="primary" round @click="popSubmit">确认</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>


<script>
import taskApi from "@/api/processTask";
import InstancePreview from "../approval/ProcessInstancePreview";
import FormAbstracts from "@/views/workspace/oa/FormAbstracts.vue";
import uniWebview from "@dcloudio/uni-webview-js";
import moment from "moment";
import { Toast } from "vant";
export default {
  name: "Candidate",
  components: { InstancePreview, FormAbstracts},
  data() {
    return {
      total: 0,
      result: [],
      showDate: false,
      error: false,
      optionInputInfo: "",
      actionLoading: false,
      maxDate: moment().toDate(),
      minDate: moment().subtract(30, "days").toDate(),
      valueSearch: "",
      option1: [],
      value1: 0,
      valueType: 0,
      instanceData: {},
      loading: false,
      finished: false,
      actionVisible: false,
      refreshing: false,
      params: {
        pageSize: 10,
        pageNo: 0,
      },
      date: moment().format("YYYY-MM-DD"),
      selectInstance: null,
      selectInstance2: null,
      processVisible: false,
      processVisible2: false,
      formList: [],
      dataList: [],
      chekedList: [],
    };
  },
  computed: {
  },
  created() {
    localStorage.removeItem("approveList");
  },
  mounted() {
  },
  methods: {
    sendTitle() {
      uniWebview.postMessage({
        data: { name: "协同-待办事宜", flag: "title" },
      });
    },
    changeCheckItem(item, index) {
      let temCarList = JSON.parse(localStorage.getItem("approveList")) || [];

      let indexFind = temCarList.findIndex(
        (v) => v.instanceId == item.instanceId
      );
      if (indexFind > -1) {
        //购物车里有
        //删除
        temCarList.splice(indexFind, 1);
        //当前数据列表更新一下
        this.dataList[index].checkFlag = "0";
      } else {
        //购物车里没有,加进去
        temCarList.push({
          ...item,
          checkFlag: "1",
        });
        //当前数据列表更新一下
        this.dataList[index].checkFlag = "1";
      }
      // 同步到本地存储(数据缓存)
      localStorage.setItem("approveList", JSON.stringify(temCarList));
    },
    checkedChange(v) {},
    popSubmit() {
      let checkedList = this.dataList.filter((item) => {
        return this.result.includes(item.instanceId);
      });
    },
    handler(action) {
      let temCarList = JSON.parse(localStorage.getItem("approveList")) || [];
      let that = this;
      if (temCarList.length > 0) {
        this.selectInstance2 = temCarList[0];
        this.processVisible2 = true;
        setTimeout(function () {
          that.$refs.childs.handler(action);

          // 需要执行的代码
        }, 500); // 定时时间
      } else {
        Toast.fail("还没有选中的审批");
      }
    },
    changeSubmitType() {
      this.dataList = [];

      this.params.pageNo = 1;
      this.getCandidateList();
    },
    onSearch(value) {},
    selectTime() {
      this.showDate = true;
    },
    onConfirm(date) {
      this.showDate = false;
      this.date = moment(date).format("YYYY-MM-DD");
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
    },
    getCandidateList() {
      if (this.option1.length > 0) {
        this.requestData();
      } else {
        taskApi
          .mySubmitTypeList({})
          .then((rsp) => {
            let typeList = rsp.data.filter(
              (item) => item.id != 1 && item.id != 2
            );
            let ArrFilterDatas = [];
            typeList.map((item) => {
              ArrFilterDatas = [...ArrFilterDatas, ...item.items];
            });
            let temArr = [
              {
                formName: "全部",
                formId: 0,
              },
              ...ArrFilterDatas,
            ];
            this.option1 = temArr.map((item, index) => {
              return {
                text: item.formName,
                value: item.formId,
              };
            });

            this.requestData();
          })
          .catch((e) => {
          });
      }
    },
    requestData() {
      this.sendTitle();
      this.loading = true;
      taskApi
        .getCandidateListFilter({
          ...this.params,
          code: this.valueType != 0 ? this.valueType : "", //审批类型
        })
        .then((rsp) => {
          this.loading = false;
          this.total = rsp.data.total;

          this.dataList = [...this.dataList, ...rsp.data.records];

          let temCarList =
            JSON.parse(localStorage.getItem("approveList")) || [];
          let mapList = this.dataList.map((item) => {
            let indexFind = temCarList.findIndex(
              (v) => v.instanceId == item.instanceId
            );

            if (indexFind < 0) {
              return {
                ...item,
                checkFlag: "0",
              };
            } else {
              //有
              return {
                ...item,
                checkFlag: "1",
              };
            }
          });
          this.dataList = [...mapList];

          // }

          if (this.dataList.length < this.total) {
          } else {
            this.finished = true;
          }
        })
        .catch((e) => {
          this.error = true;
        });
    },
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
        this.params.pageNo = 1;
      } else {
        //this.dataList = [];
        //  加载下一页面的时候改下起始页数
        this.params.pageNo = this.params.pageNo + 1;
        if (this.error) {
          this.params.pageNo = 1;
        }
      }

      this.getCandidateList();
    },
    onRefresh() {
      this.finished = false;

      this.loading = true;
      this.onLoad();
    },
    showProcess(row) {
      this.processVisible = true;
      this.selectInstance = row;
    },
    handlerAfter() {
      this.processVisible = false;
      this.params.pageNo = 1;
      this.dataList = [];
      this.getCandidateList();
    },
    handlerAfter2() {
      this.processVisible2 = false;
      this.params.pageNo = 1;
      this.dataList = [];
      this.getCandidateList();
    },
  },
};
</script>

<style scoped lang="less">
.unfinish-outer {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-color: #fff;
  /deep/ .van-dropdown-menu__title {
    width: 12rem;
  }
  /deep/ .van-checkbox__icon {
    font-size: 2rem;
  }
  /deep/ .van-calendar__footer {
    .van-button {
      width: 100%;
    }
  }
  /deep/.van-dropdown-menu__bar {
    box-shadow: none !important;
    background-color: #fff;
  }
  /deep/ .el-drawer__header {
    font-size: 1.6rem !important;
    color: #333;
  }
  /deep/ .el-drawer__close-btn {
    font-size: 1.6rem !important;
    color: #333;
  }
  /deep/ .van-dropdown-menu__title {
    font-size: 1.3rem;
  }
  /deep/ .van-search {
    padding: 0;
    .van-icon {
      font-size: 1.5rem;
    }
  }
  /deep/ .van-cell {
    line-height: 2.3rem;
    font-size: 1.3rem;
  }
  /deep/ .van-dropdown-menu__bar {
    height: 3rem;
  }
  /deep/ .van-sticky--fixed {
    background-color: #fff;
  }
  .groups-outer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    .checkout-outer {
      padding-right: 1rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .popup-sku-con-outer {
    width: 90vw;
    padding: 1rem;
    .pop-title-con {
      margin: 1rem 0;
      font-size: #333;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .input-outer {
      margin: 1rem 0;
      font-size: 1.3rem;
    }
    .edit-input {
      margin: 1rem 0;
    }
    .option-btns {
      display: flex;
      justify-content: center;
      .van-button {
        width: 80%;
        background-color: #006c50;
        margin: 1rem 0;
        font-size: 1.5rem;
      }
    }
    /deep/ .el-textarea {
      font-size: 1.2rem;
    }
  }

  .top-outer {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    padding: 0 2rem;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;

    .search {
      width: 60vw;
    }
    .more-submit {
      padding: 1rem 1.5rem;
      background-color: #006c50;
      color: #fff;
      font-size: 1.3rem;
      border-radius: 2rem;
    }
  }
  .top-outer2 {
    margin-top: 1rem;
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    font-size: 1.3rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    .left {
      flex: 1;
      display: flex;
      align-items: center;
      .type-css {
        padding-bottom: 0.18rem;
      }
    }
    .right {
      padding-bottom: 0.18rem;
      color: #006c50;
      display: flex;
      .item-btn {
        margin-right: 1rem;
      }
    }
  }
  .mlist {
    width: 100%;
    padding: 2rem;

    flex: 1;
    /deep/ .van-checkbox-group {
      .van-checkbox__icon {
        margin-right: 1rem;
      }
      .van-checkbox__label {
        flex: 1;
      }
    }
    .item {
      padding: 0.8rem;
      flex: 1;

      background-color: #fff;
      border-radius: 1.5rem;
      box-sizing: border-box;
      box-shadow: 0px 0.2rem 1rem rgba(1, 12, 23, 0.06);

      margin-bottom: 1.5rem;
      position: relative;
      .line {
        display: flex;
        align-items: flex-start;
        padding: 0.3rem 0;
        .t-title {
          width: 12rem;
          font-size: 1.3rem;
          color: #333;
        }
        .t-txt {
          font-size: 1.3rem;
          color: #666;
        }
      }
      .status {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.3rem;
        background: #eef5f3;
        color: #006c50;
        line-height: 2rem;
        height: 2rem;
        border-radius: 0.5rem;
        padding: 0 0.8rem;
        box-sizing: border-box;
      }
    }
  }
}
</style>
<style lang="less"></style>
